import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { transactionsApi } from "src/api";
import { Table } from "src/components/tables/Table";
import { columnConfigTransactions } from "src/components/transactions/TransactionsTable/TransactionsTable.tableConfig";
import { DatesType } from "src/pages/Reports/Reports.types";
import { EmptyTable } from "../../tables/EmptyTable";
import { TransactionSearchRequestData } from "src/types";
import { ChargerTransactionCard } from "./ChargerTransactionCard";
import { TableRowCounter } from "../../tables/TableRowCounter";
import { TablePagination } from "../../tables/TablePagination";
import { ResponsiveTable } from "../../common/ResponsiveTable";
import { DriverSearch } from "./DriverSearch";
import { rootSx, titleSx, tableFooterSx, filtersSx } from "./ChargerTransactionsTable.sx";
import { ChargerTransactionsTableType } from "./ChargerTransactionsTable.types";
import ability from "src/permissions/ability";
import { useAppSelector } from "src/app/hooks";

export const ChargerTransactionsTable: FC<ChargerTransactionsTableType> = ({ businessId, chargerId }) => {
  const intl = useIntl()
  const { authInfo } = useAppSelector((state) => state.common);

  const title = intl.formatMessage({ id: 'transactions' })
  const canReadUsers = ability.can('read', 'users')

  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [getTransactions, transactions] = transactionsApi.useLazyGetTransactionsQuery()
  const [currentDriverId, setCurrentDriverId] = useState<number | undefined>(undefined)

  const [dates, setDates] = useState<DatesType>({
    allSessions: {
      from: undefined,
      to: dayjs().valueOf()
    }
  })

  const handleCurrentDriverId = (id?: number) => {
    setCurrentDriverId(id)
  }
  
  const handlePerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = +e.target.value
    if (typeof val === 'number') {
      setPerPage(val)
      setCurrentPage(0)
    }
  }
  
  const handleChangePage = (e: number) => {
    setCurrentPage(e)
  }

  const getData = async (data: TransactionSearchRequestData) => {
    await getTransactions({
      data,
      params: {size: perPage, page: currentPage}})
  }

  useEffect(() => {
    getData({
      businessId,
      chargerId,
      startFrom: dates.allSessions.from,
      startTo: dates.allSessions.to,
      userId: canReadUsers ? currentDriverId : authInfo.id
    })
  }, [dates, perPage, currentPage, currentDriverId])

  const handleChangeDates = (
    dates: DatesType  ) => {
    setDates(dates)
  }

  return (
    <Box sx={rootSx}>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={filtersSx}>
          <DriverSearch
            handleSetDriverId={handleCurrentDriverId}
            businessId={businessId}
            handleChangeDates={handleChangeDates}
            dates={dates}
          />
        </Box>
      </Box>
      <ResponsiveTable
        desktopElement={
          <Table
            sx={{
              border: 'none'
            }}
            rows={transactions.data?.content ?? []}
            hideFooter
            columns={columnConfigTransactions}
          />
        }
        responsiveElement={
          <>
            {transactions?.data?.content?.map(item => (
              <ChargerTransactionCard key={item?.id}
                {...item} />
            ))}
            {!transactions?.data?.content?.length && <EmptyTable />}
          </>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handlePerPageChange}
          defaultValue={perPage}
        />
        <TablePagination
          totalPages={transactions?.data?.totalPages ?? 0}
          page={currentPage}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
