import { SxProps, Theme } from "@mui/material"
import theme from "../../../../theme"

export const filterWrapperSx: SxProps<Theme> = {
  position: 'relative',
}
export const filterButtonSx: SxProps<Theme> = {
  '&.MuiIconButton-root': {
    background: theme.palette.background.default,
    border: `${theme.spacing(1/8)} solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.spacing(4/8),
    height: "2rem"
  }
}
export const filterTextSx: SxProps<Theme> = {
  [theme.breakpoints.down('lg')]: {
    display: "none"
  }
}

export const formWrapperSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    maxWidth: theme.spacing(300/8),
    width: '100%',
    px: 2,
    pt: 2
  },
  '& .MuiInputBase-root': {
    height: theme.spacing(53/8)
  },
  '& .MuiFormControl-root': {
    mt: 0
  }
}
export const filterIconActiveSx: SxProps<Theme> = {
  '&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium': {
    fill: theme.palette.primary.main
  }
}
export const popupFooterSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'end',
  pt: 1,
  pb: 2
}