import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "src/app/hooks";
import { VerifyEmailIcon } from "src/components/icons/VerifyEmailIcon";
import { clearAuthInfo, preloaderChangeStatus } from "src/stores/common/commonSlice";
import { SubmitButton } from "../SubmitButton";
import { innerSx, textWrapperSx } from "./ConfirmUserEmail.sx";
import { store } from "src/app/store";
import { registerApi } from "src/api";
import { NotifyError, NotifySuccess } from "src/utils/notification";

export const ConfirmUserEmail: FC = () => {
  const dispatch = useAppDispatch();
  const authinfo = store?.getState()?.common?.authInfo;

  const [triggerResend] = registerApi.useConfirmResendMutation();

  const handleLogOut = () => {
    localStorage.clear()
    dispatch(clearAuthInfo())
  }
  const handleResend = async () => {
    try {
      store.dispatch(preloaderChangeStatus(true));
      await triggerResend(authinfo.emailAddress).unwrap();
      NotifySuccess('Verification sent')
    } catch (error) {
      NotifyError('Resend ERROR');
    } finally {
      store.dispatch(preloaderChangeStatus(false));
    }
  }
  return (
    <Box sx={innerSx}>
      <VerifyEmailIcon />
      <Box sx={textWrapperSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="verifyYourEmail" />
        </Typography>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center', gap: 2}}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResend}
        >
          <FormattedMessage id="resendVerification" />
        </Button>
        <SubmitButton
          text={'logOut'}
          handler={handleLogOut}
          align={'center'}
        />
      </Box>
      
    </Box>
  )
}
