import { ArrowForwardIos } from "@mui/icons-material";
import { Box, capitalize, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { chargersApi } from "src/api";
import { ChargersIcon } from "src/components/icons/ToolbarIcons";
import theme from "src/theme";
import { ProceedCurrenciesEnum } from "src/utils/consts";
import { actionsWrapperSx, infoSx, infoValueSx, infoWrapperSx, linkWrapperSx, wrapperSx } from "./UserActions.sx";
import { UserActionsType } from "./UserActions.types";
import ability from "../../../permissions/ability";
import { ContractTitle } from "./ContractTitle";
import { fixedDigits } from "src/utils/fixedDigits";

export const UserActions: FC<UserActionsType> = ({ data }) => {
  const intl = useIntl()

  const [getChargerInfo, chargerInfo] = chargersApi.useLazyGetChargePointByIdQuery()

  useEffect(() => {
    data.chargePoint && getChargerInfo({businessId: data.business.id, chargerId: data.chargePoint.id})
  }, [])

  const contract = data?.contracts?.find(contract => contract.active)

  const annualWhLimit = contract?.tariff?.annualWhLimit
  const pricePerKWh = contract?.tariff?.pricePerKWh
  
  const canReadContract = ability.can('read', 'contract')

  return (
    <Box
      sx={actionsWrapperSx}
    >
      <Grid
        container
        rowSpacing={{xs: 2}}
        columnSpacing={{xs: 0, md: 2}}
        height='100%'
      >
        {(data.chargePoint) && 
        <Grid
          item
          xs={12}
        >
          <Box
            sx={wrapperSx}
          >
            <Link to={`/app/chargers/${data.chargePoint.id}`} >
              <Box sx={linkWrapperSx}>
                <ChargersIcon sx={{fill: theme.palette.primary.main}}/>
                <Typography variant="subtitle1">
                  <FormattedMessage id="charger" />
                </Typography>
              </Box>
              <ArrowForwardIos sx={{fill: theme.palette.text.primary}}/>
            </Link>
            {chargerInfo.data && <Box sx={infoWrapperSx}>
              <Box sx={infoSx}>
                <Typography variant="caption">
                  {capitalize(intl.formatMessage({id:"annualWhLimit"}))}
                </Typography>
                <Box sx={infoValueSx}>
                  <Typography variant="subtitle1">
                    {annualWhLimit ? (annualWhLimit / 1000).toFixed(0) : 0 }
                  </Typography>
                  <Typography variant="subtitle1">
                    <FormattedMessage id="kwh" />
                  </Typography>
                </Box>
              </Box>
              <Box sx={infoSx}>
                <Typography variant="caption">
                  {capitalize(intl.formatMessage({id:"costPerKw"}))}
                </Typography>
                <Box sx={infoValueSx}>
                  <Typography variant="subtitle1">
                    {pricePerKWh ? fixedDigits(pricePerKWh, 10000) : 0 }
                  </Typography>
                  <Typography variant="subtitle1">
                    {contract?.tariff?.currency && ProceedCurrenciesEnum[contract?.tariff?.currency]}
                  </Typography>
                </Box>
              </Box>
            </Box>}
          </Box>
        </Grid>
        }
        {contract && 
        <Grid
          item
          xs={12}
        >
          <Box
            sx={wrapperSx}
          >
            {
              canReadContract ?
                <Link to={`/app/contracts/${contract.id}`}>
                  <ContractTitle canReadContract={canReadContract}/>
                </Link> :
                <ContractTitle canReadContract={canReadContract}/>
            }
            <Box sx={infoWrapperSx}>
              <Box sx={infoSx}>
                <Typography variant="caption">
                  {capitalize(intl.formatMessage({id:"annualWhLimit"}))}
                </Typography>
                <Box sx={infoValueSx}>
                  <Typography variant="subtitle1">
                    {annualWhLimit ? (annualWhLimit / 1000).toFixed(0) : 0}
                  </Typography>
                  <Typography variant="subtitle1">
                    <FormattedMessage id="kwh" />
                  </Typography>
                </Box>
              </Box>
              <Box sx={infoSx}>
                <Typography variant="caption">
                  {capitalize(intl.formatMessage({id:"costPerKw"}))}
                </Typography>
                <Box sx={infoValueSx}>
                  <Typography variant="subtitle1">
                    {pricePerKWh ? fixedDigits(pricePerKWh, 10000) : 0}
                  </Typography>
                  <Typography variant="subtitle1">
                    {ProceedCurrenciesEnum[contract.tariff.currency]}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        }
      </Grid>
    </Box>
  )
}
