import { FC } from "react";
import dayjs from "dayjs";
import { Box, Button, Grid, Typography } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { usersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { getHelperText } from "src/utils/forms";
import {
  editUserNameFormSchema,
  EditUserNameFormType
} from "../formData/editUser";
import { TWO_YEARS } from "../../../utils/consts";
import { UserDTO, UserNameUpdateDTO } from "../../../types";
import { EditUserNameFormTypes } from "./EditUserNameForm.types";
import { actionsWrapperSx, deleteBtnSx, formContentSx, formSx } from "./EditUserNameForm.sx";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";

type EditUserNameMutationTrigger = ReturnType<typeof usersApi.useUpdateUserNameMutation>[0];
type DeleteUserMutationTrigger = ReturnType<typeof usersApi.useDeleteUserAccountMutation>[0];

const handleSubmit =
  (
    trigger: EditUserNameMutationTrigger,
    dispatch: AppDispatch,
    userId: number | undefined,
    businessId: number | undefined,
    handleClose: () => void
  ): FormikConfig<EditUserNameFormType>["onSubmit"] => {
    return async (value) => {
      try {
        dispatch(preloaderChangeStatus(true));
        const {
          firstName,
          lastName
        } = value;
        const data: UserNameUpdateDTO = {
          businessId,
          firstName,
          lastName
        };
        if (userId) {
          await trigger({data, userId}).unwrap()
        }
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };
  }

const handleDeleteAccount = (
  dispatch: AppDispatch,
  userData: UserDTO | undefined,
  trigger: DeleteUserMutationTrigger,
  handleShowDeleteModal: (status: boolean) => () => void,
  handleClose: () => void,
  navigate: NavigateFunction
) => async () => {
  const contract = userData?.contracts.find(contract => contract.active)
  const contractDate = contract?.created
    ? dayjs(contract?.created)
    : dayjs();
  const today = dayjs();
  const diff = today.diff(contractDate, "days");
  const canDelete = (diff > TWO_YEARS || !contract) && userData?.id && userData?.business?.id
  if (canDelete) {
    try {
      dispatch(preloaderChangeStatus(true));
      await trigger({
        userId: userData.id,
        data: {businessId: userData.business.id}
      }).unwrap()
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } finally {
      navigate( "/app/users");
      dispatch(preloaderChangeStatus(false));
    }
  } else {
    handleShowDeleteModal(true)();
    handleClose();
  }
}

export const EditUserNameForm: FC<EditUserNameFormTypes> = ({handleClose, userData, handleShowDeleteModal }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useUpdateUserNameMutation();
  const [triggerDeleteAccount] = usersApi.useDeleteUserAccountMutation();
  const navigate = useNavigate();
  
  const EditUserInitialValue = {
    firstName: userData?.firstName ?? "",
    lastName: userData?.lastName ?? ""
  }
  
  return (
    <Formik
      initialValues={EditUserInitialValue}
      validationSchema={editUserNameFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, userData?.id, userData?.business?.id,  handleClose)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }: FormikProps<EditUserNameFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={12}
                  md={6}
                  item
                >
                  <AMPInput
                    value={values["firstName"]}
                    type={"firstName"}
                    label={"firstName"}
                    input={"firstName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["firstName"] && Boolean(errors["firstName"])
                    }
                    helperText={getHelperText("firstName", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  md={6}
                  item
                >
                  <AMPInput
                    value={values["lastName"]}
                    type={"lastName"}
                    label={"lastName"}
                    input={"lastName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["lastName"] && Boolean(errors["lastName"])}
                    helperText={getHelperText("lastName", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
              >
                <Grid
                  xs={12}
                  md={3}
                  item
                >
                  <Button
                    onClick={handleDeleteAccount(
                      dispatch,
                      userData,
                      triggerDeleteAccount,
                      handleShowDeleteModal,
                      handleClose,
                      navigate
                    )}
                    variant="outlined"
                    color="error"
                    sx={deleteBtnSx}
                  >
                    {intl.formatMessage({id: "deleteAccount"})}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
