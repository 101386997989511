import { FC, useEffect, useState } from "react"
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { Form, Formik, FormikConfig, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { authApi } from "src/api"
import { loginInitialValues } from "../formData/common/initialData"
import { loginSchema, LoginType } from "../formData/login"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { useAppDispatch } from "src/app/hooks"
import { Link, NavigateFunction, useNavigate } from "react-router-dom"
import { AppDispatch } from "src/types"
import { SubmitButton } from "src/components/common/SubmitButton"
import { changeLang, preloaderChangeStatus, setActiveBusiness } from "src/stores/common/commonSlice"
import CryptoJs from 'crypto-js'
import { cryptoKey } from "src/axiosBaseQuery/interceprtors"
import { NotifyError } from "src/utils/notification"
import { ContractStatusEnum, LanguageEnum, LanguageShortEnum, RegistrationStatusEnum, RoleEnum } from "src/enums"
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "src/utils/helper"
import { actionsWrapperSx, LoginFormSx } from "./LoginForm.sx"

type LoginMutationTrigger = ReturnType<typeof authApi.useLoginMutation>[0]

const handleSubmit = (trigger: LoginMutationTrigger, dispatch: AppDispatch, navigate: NavigateFunction, remember = false): FormikConfig<LoginType>['onSubmit'] => (async ({ emailAddress, password }) => {
  dispatch(preloaderChangeStatus(true))
  try {
    const crypto = CryptoJs.AES.encrypt(JSON.stringify(remember), cryptoKey.toString()).toString()
    sessionStorage.setItem('remember', JSON.stringify(true))
    localStorage.setItem('remember', crypto)
    const result = await trigger({
      emailAddress,
      password
    }).unwrap()
    const languagesMap = {
      [LanguageEnum.ENGLISH]: LanguageShortEnum.EN,
      [LanguageEnum.FRENCH]: LanguageShortEnum.FR,
      [LanguageEnum.DEUTSCH]: LanguageShortEnum.DE,
    }
    dispatch(changeLang(languagesMap[result.payload.language ?? LanguageEnum.ENGLISH]
    ))
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const LoginForm: FC = () => {
  const intl = useIntl();

  const [trigger, response] = authApi.useLoginMutation()
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [remember, setRemember] = useState<boolean>(false);

  useEffect(() => {
    if (response.data) {
      const registrationStatus = response.data?.payload?.registrationStatus;
      const profileActive = registrationStatus === RegistrationStatusEnum.ACTIVATED;
      response.data.payload.business && dispatch(setActiveBusiness(response.data.payload.business.id))
      if (response.data.payload.roles[0].role === RoleEnum.CLIENT_MANAGER) {
        navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users]))
        return
      }
      if (response.data.payload.roles[0].role === RoleEnum.DRIVER) {
        const operational = response.data.payload.contracts?.find(contract => contract.status === ContractStatusEnum.OPERATION)
        if (profileActive && operational && response.data.payload.chargePoint) {
          return navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, String(response.data.payload.chargePoint.id)]))
        } else if (profileActive && operational) {
          navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts, String(operational?.id ?? response.data.payload.contracts[0]?.id)]))
        } else {
          return navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.welcome]));
        }
      } else {
        navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations]));
      }
    }
  }, [response])
  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={loginSchema}
      onSubmit={handleSubmit(trigger, dispatch, navigate, remember)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }: FormikProps<LoginType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={LoginFormSx}>
            <AMPInput
              value={values['emailAddress']}
              type={'emailAddress'}
              label={'emailAddress'}
              input={'emailAddress'}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched['emailAddress'] && Boolean(errors['emailAddress'])}
              helperText={getHelperText('emailAddress', touched, errors, intl)}
              placeholder='emailPlaceholder'
            />
            <AMPInput
              value={values['password'] as string}
              type={'password'}
              label={'password'}
              input={'password'}
              onChange={handleChange}
              onBlur={handleBlur}
              hasError={touched['password'] && Boolean(errors['password'])}
              helperText={getHelperText('password', touched, errors, intl)}
              placeholder='passwordPlaceholder'
            />
            <FormControlLabel
              label={intl.formatMessage({ id: 'remember' })}
              control={
                <Checkbox
                  color="secondary"
                  value={remember}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setRemember(checked)}
                />
              }
            />
            <Box sx={actionsWrapperSx}>
              <Link
                to="/forgot"
              >
                <Typography
                  variant="body1"
                  color="primary"
                >
                  <FormattedMessage id="forgotPassword" />
                </Typography>
              </Link>

              <SubmitButton
                text="login"
                isSubmitting={isSubmitting}
                rounding
                color="brand"
              />
            </Box>
          </Box>
        </Form>
      )
      }

    </Formik>
  );
}
