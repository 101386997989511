import { FC, useEffect, useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { FormikConfig } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { contractsApi, tariffsApi, usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ContractForm } from "src/components/forms/ContractForm";
import { ContractType } from "src/components/forms/formData/contract";
import { ListOfTariffs } from "src/components/tariffs/ListOfTariffs";
import { RoleEnum, TariffsStatusEnum } from "src/enums";
import { preloaderChangeStatus, setAuthInfo } from "src/stores/common/commonSlice";
import { AppDispatch, AuthUserDTO, CreateContractRequest } from "src/types";
import { contentItemSx, separatorSx, stepperSx, stepIconsSx, emptyTariffsListSx } from "./CreationContract.sx";
import { isErrorObject, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { allRegionsValue } from "../../../utils/consts";

type CreateContractMutationTrigger = ReturnType<typeof contractsApi.useCreateContractMutation>[0]
type GetUserByIdQuery = ReturnType<typeof usersApi.useLazyGetUserByIdQuery>[0]

const handleSubmit = (trigger: CreateContractMutationTrigger, dispatch: AppDispatch, authInfo: AuthUserDTO, activeTariff: number, handleClose: () => void, getUserById: GetUserByIdQuery): FormikConfig<ContractType>['onSubmit'] => (async (value) => {
  const values = valuesTrimmer(value)
  const data: CreateContractRequest = {
    address: {
      region: values.region === allRegionsValue ? undefined : values.region,
      city: values.city,
      country: values.country,
      houseNumber: values.houseNumber,
      street: values.street,
      zipCode: values.zipCode
    },
    phoneNumber: values.phoneNumber,
    tariffId: activeTariff,
    userId: authInfo.id
  }
  !data.address?.country && delete data.address

  const isDriver = authInfo.roles.
    map((role: {role: RoleEnum}) => role.role).
    includes(RoleEnum.DRIVER);
  
  dispatch(preloaderChangeStatus(true))
  try {
    await trigger(data).unwrap();
    handleClose();
    if (isDriver) {
      try {
        
        const response = await getUserById({
          userId: authInfo.id,
          businessId: authInfo.business.id
        }).unwrap();
  
        console.log('AFTER REQUESTING CHARGER', response)
  
        dispatch(setAuthInfo(response))
      } catch(e) {
        console.error('COULD NOT GET CURRENT DRIVER', e);
      }
    }
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const CreationContract: FC = () => {
  const intl = useIntl();
  const dialogTitle = intl.formatMessage({ id: "createNewContract" });
  const [open, setOpen] = useState<boolean>(false);

  const [ trigger ] = contractsApi.useCreateContractMutation();
  const [getDriver] = usersApi.useLazyGetUserByIdQuery();
  const dispatch = useAppDispatch()

  const { authInfo } = useAppSelector((state) => state.common);
  const [getTariffs, tariffs] = tariffsApi.useLazyGetTariffsQuery()
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeTariff, setActiveTariff] = useState<number>(0)
  const steps = [
    {
      title: intl.formatMessage({ id: "tariffs" }),
      description: intl.formatMessage({ id: "chooseTariff" }),
    },
    {
      title: intl.formatMessage({ id: "generalInfo" }),
      description: intl.formatMessage({ id: "identity" }),
    },
  ];
  
  const handleGetTariffs = async () => {
    if(authInfo?.business?.id){
      await getTariffs({data: {
        businessId: authInfo.business.id,
        status: TariffsStatusEnum.ACTIVE,
        country: authInfo.parkingAddress.country,
        region: authInfo.parkingAddress.region,
      }}).then(async (res) => {
        if (!res?.data?.content?.length) {
          await getTariffs({data: {
            businessId: authInfo.business.id,
            status: TariffsStatusEnum.ACTIVE,
            country: authInfo.parkingAddress.country,
            emptyRegion: true
          }})
        }
      })
    }
  }
  useEffect(() => {
    handleGetTariffs()
  }, [])

  const handleSelectCard = (id: number) => {
    setActiveTariff(id)
  }

  const changeStep = (step:number) => () => setActiveStep(step)

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={5}
      >
        <Grid
          item
          xs={12}
          sx={contentItemSx}
        >
          <img src="/static/images/create-request.png" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={contentItemSx}
        >
          <Typography
            variant="body1"
            color="neutral.500"
            fontSize="1rem"
          >
            <FormattedMessage id="welcomeContent" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={contentItemSx}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpen}
          >
            <FormattedMessage id="createRequest" />
          </Button>
        </Grid>
      </Grid>
      <AMPModal
        handleClose={handleClose}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Stepper
          activeStep={activeStep}
          sx={stepperSx}
          connector={<ArrowForwardIos sx={separatorSx}/>}
        >
          {steps.map((label, index) => (
            <Step
              key={index}              
            >
              <StepLabel
                StepIconProps={{
                  sx: stepIconsSx
                }}
              >
                <Typography
                  variant="h4"
                  lineHeight="1.2"
                >
                  {label.title}
                </Typography>
                <Typography variant="body1">{label.description}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {
          (activeStep === 0 && tariffs.data && Boolean(tariffs.data?.content.length)) && (
            <ListOfTariffs
              submitTitle="nextStep"
              content={tariffs.data.content}
              handleSelectCard={handleSelectCard}
              activeItem={activeTariff}
              changeStep={changeStep(1)}
              handleClose={handleClose}
              disableSubmit={activeTariff === 0}
            />
          )
        }
        {
          (activeStep === 0 && tariffs.data && Boolean(!tariffs.data?.content.length)) && (
            <Box sx={emptyTariffsListSx}>
              <Typography variant="subtitle1">
                <FormattedMessage id="noActiveTariffs"/>
              </Typography>
            </Box>
          )
        }
        {activeStep === 1 && <ContractForm
          handleClose={handleClose}
          authInfo={authInfo}
          changeStep={changeStep(0)}
          handleSubmit={handleSubmit(trigger, dispatch, authInfo, activeTariff, handleClose, getDriver)}
        />}
      </AMPModal>
    </>
  );
};
