import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "src/axiosBaseQuery"
import { ResetPasswordRequest } from "src/types/auth"
import { setAuthInfo } from "src/stores/common/commonSlice"
import { getBaseURL } from "src/utils/helper"
import { DefaultAuthResponse, DefaultResponse } from "src/types/common"

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/auth'),
  }),
  endpoints:(build) => {
    return {
      login: build.mutation<DefaultAuthResponse, {emailAddress: string, password: string}>({
        query: (data) => ({ url: '/login', method: 'post', data }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(setAuthInfo(data.payload))
          } catch (err) {
            console.error(err);
          }
        }
      }),
      refreshToken: build.query<DefaultResponse, { token: string }>({
        query: (params) => ({ url: `/refresh/token`, method: 'post', params })
      }),
      forgotPassword: build.query<DefaultResponse, { emailAddress: string }>({
        query: (data) => ({ url: '/password/forget', method: 'post', data })
      }),
      resetPassword: build.mutation<DefaultResponse, ResetPasswordRequest>({
        query: ({data, params}) => ({ url: '/password/reset', method: 'post', data, params })
      })
    }
  },
})
