import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AMPNavbar } from '../../components/common/AMPNavbar';
import { AMPDrawer } from '../../components/common/AMPDrawer';
import { Container, Box, DrawerProps, SvgIcon } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect, useState } from 'react';
import { RoleEnum } from 'src/enums';
import { Can } from "../../permissions/Can";
import { AppRouteEnum, AppRouteSpace } from 'src/utils/helper';
import {
  pageWrapperSx,
  pageContentSx,
  contentSx,
  notificationsSx,
  notificationsBoxSx,
  notificationsNumberSx
} from './DashboardLayout.sx';
import theme from 'src/theme';
import { DashboardLayoutProvider } from './DashboardLayout.context';
import { NotificationsIcon } from "../../components/icons/ToolbarIcons";
import { NotificationMarker } from "../../components/common/NotificationMarker";
import { usersApi } from 'src/api';
import { setAuthInfo } from 'src/stores/common/commonSlice';

export const DashboardLayout = () => {
  const { authInfo } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const isDrawerMustHide = (authInfo?.contracts?.length && authInfo?.roles?.[0]?.role === RoleEnum.DRIVER) || authInfo?.roles?.[0]?.role !== RoleEnum.DRIVER
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [drawerVariant, setDrawerVariant] = useState<DrawerProps['variant']>('temporary');
  const [responsive, setResponsive] = useState<boolean>(false)
  const [getDriver] = usersApi.useLazyGetUserByIdQuery();

  const getCurrentDriver = async () => {
    const isDriver = authInfo.roles.
      map((role: {role: RoleEnum}) => role.role).
      includes(RoleEnum.DRIVER);
    if (isDriver) {
      try {
        const response = await getDriver({
          userId: authInfo.id,
          businessId: authInfo.business.id
        }).unwrap();

        console.log('LAYOUT', response)

        dispatch(setAuthInfo(response))
      } catch(e) {
        console.error('COULD NOT GET CURRENT DRIVER', e);
      }
    }
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  useEffect(() => {
    if (!Object.keys(authInfo).length) {
      localStorage.clear()
      navigate(AppRouteSpace([AppRouteEnum.login]))
    }
  }, [authInfo])

  useEffect(() => {
    getCurrentDriver();
    if (window.innerWidth > theme.breakpoints.values['md']) {
      setDrawerVariant('permanent');
      handleOpenDrawer()
      setResponsive(false)
    } else {
      setDrawerVariant('temporary');
      handleCloseDrawer()
      setResponsive(true)
    }
  }, [])

  useEffect(() => {
    getCurrentDriver();
  }, [location])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > theme.breakpoints.values['md']) {
        setDrawerVariant('permanent');
        setResponsive(false)
        handleOpenDrawer()
      } else {
        setDrawerVariant('temporary');
        setResponsive(true)
        handleCloseDrawer()
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <DashboardLayoutProvider value={responsive}>
      <Box
        sx={pageWrapperSx}
      >
        {isDrawerMustHide && (
          <AMPDrawer
            handleCloseDrawer={handleCloseDrawer}
            drawerVariant={drawerVariant}
            open={openDrawer}
          />
        )}
        <Box
          sx={pageContentSx}
        >
          <Can
            I="read"
            a="notifications"
          >
            <Box sx={notificationsBoxSx}>
              <Box sx={notificationsSx}>
                <Box sx={notificationsNumberSx}>
                  <NotificationMarker/>
                </Box>
                <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.notifications])}>
                  <SvgIcon
                    component={NotificationsIcon}
                    viewBox="0 0 20 20"
                  />
                </Link>
              </Box>
            </Box>
          </Can>
          {location.pathname !== AppRouteSpace([AppRouteEnum.app, AppRouteEnum.welcome]) && <AMPNavbar handleOpenDrawer={handleOpenDrawer} />}
          <Box>
            <Container
              maxWidth={location.pathname !== AppRouteSpace([AppRouteEnum.app, AppRouteEnum.welcome]) ? 'xl' : false}
              sx={contentSx}
            >
              <Outlet />
            </Container>
          </Box>
        </Box>
      </Box>
    </DashboardLayoutProvider>
  );
};
