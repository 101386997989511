import { alpha, SxProps, Theme } from "@mui/material";
import { ContractStatusEnum } from "src/enums";

export const wrapperSx = (theme: Theme):SxProps<Theme> => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(10/8),
  pt: 3,
  mt: 3
})

export const titleSx = (theme: Theme):SxProps<Theme> => ({
  fontSize: theme.spacing(16/8),
  fontWeight: 600,
  pl: 3,
  pb: 3
})

export const tablesx = (theme: Theme):SxProps<Theme> => ({
  border: 'none',
  minHeight: theme.spacing(300/8),
  mx: 3,
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
})

export const colTitle:SxProps<Theme> = {
  textTransform: "uppercase"
}

export const statusSx = (theme: Theme, status: ContractStatusEnum):SxProps<Theme> => {
  const colorsMap = {
    [ContractStatusEnum.ARCHIVED]: {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[200]
    },
    [ContractStatusEnum.OPERATION]: {
      color: theme.palette.primary.dark,
      backgroundColor: alpha(theme.palette.primary.light, 0.4)
    },
    [ContractStatusEnum.INSTALLATION]: {
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light
    },
    [ContractStatusEnum.REJECTED]: {
      color: theme.palette.error.dark,
      backgroundColor: alpha(theme.palette.error.light, 0.2)
    },
    [ContractStatusEnum.VALIDATION]: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.light, 0.1)
    },
    [ContractStatusEnum.WAIT_FOR_SIGNATURE]: {
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[100]
    }
  }
  return {
    px: 1,
    py: 1,
    borderRadius: theme.spacing(8/8),
    ...colorsMap[status]
  }
}