import { FC, useState } from "react";
import { PdfDocumentType } from "./PdfDocument.types";
import {Document, Page, pdfjs} from 'react-pdf/dist/umd/entry.webpack';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { wrapperSx, titleSx, documentButtonsSx, documentWrapperSx, SubmitBtnSx } from "./PdfDocument.sx";
import { FormattedMessage, useIntl } from "react-intl";
import { ArrowBackIosNew, ArrowForwardIos, Download } from "@mui/icons-material";
import DropZone from "../DropZone";
import { useAppDispatch } from "../../../app/hooks";
import { SubmitButton } from "../SubmitButton";
import { AppDispatch } from "../../../types";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { store } from "../../../app/store";
import { contractsApi } from "../../../api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import ability from "src/permissions/ability";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const handleSubmit = (
  dispatch: AppDispatch,
  businessId: number | undefined,
  contractId: number | undefined,
  file?: File[] | null
) => async () => {
  try {
    dispatch(preloaderChangeStatus(true))
    if(file && contractId && businessId) {
      const formData = new FormData()
      formData.append('file', file[0])
      await store.dispatch(contractsApi.endpoints.uploadContract.initiate({
        contractId,
        businessId,
        file: formData
      })).unwrap()
    }
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
};

export const PdfDocument: FC<PdfDocumentType> = ({pdfLink, userName, date, businessId, contractId}) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const canUploadContract = ability.can('read', 'uploadContract')

  const [file, setFile] = useState<File[] | null>(null)
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: {numPages : number}) => {
    setNumPages(numPages);
  }
  
  const handleNextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  const handlePrevPage = () => {
    setPageNumber(pageNumber - 1)
  }
  
  const handleAddFile = (draggedFile: File[] | null)=> {
    setFile(draggedFile)
  }

  const handleDownloadContract = () => {
    if(pdfLink){

      const tempLink = document.createElement('a');
      tempLink.href = pdfLink;
      tempLink.setAttribute('download', `${userName}_contract.pdf`);
      tempLink.click();
      // window.URL.revokeObjectURL(tempLink.href)
    }
  }

  return (
    <Box sx={wrapperSx}>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="contract" />
        </Typography>
        <IconButton onClick={handleDownloadContract}>
          <Download />
        </IconButton>
      </Box>
      {!pdfLink &&
        <>
          <Typography
            variant="body1"
            sx={{mb: 1}}
          >
            {intl.formatMessage({ id: "attachedFile" })}
          </Typography>
          <DropZone
            handleFile={handleAddFile}
            isImage={false}
          />
          {canUploadContract && 
          <Stack sx={SubmitBtnSx}>
            <SubmitButton
              text="submit"
              isSubmitting={!file}
              handler={handleSubmit(dispatch, businessId, contractId, file )}
            />
          </Stack>}
        </>
      }
      {pdfLink && 
        <Box sx={documentWrapperSx}>
          <Document
            file={pdfLink}
            renderMode="canvas"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              className="pdfPage"
              renderAnnotationLayer={false}
            />
          </Document>
          <Box sx={documentButtonsSx}>
            <IconButton
              onClick={handlePrevPage}
              disabled={pageNumber === 1}
            >
              <ArrowBackIosNew />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              disabled={pageNumber === numPages}
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>
      }
    </Box>
  )
}
