import { FC } from "react";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";
import { ContractHistoryCardProps } from "./ContractHistoryCard.types";

export const ContractHistoryCard:FC<ContractHistoryCardProps> = ({
  name, 
  link,
  created,
  signed,
  archived,
  status,
  operation
}) => {
  return (
    <TableCard>
      <TableRow
        titleId='name'
        link={link}
        value={name}
        isId
      />
      <TableRow
        titleId='createdOnColumnHeader'
        value={created}
      />
      <TableRow
        titleId='signedOn'
        value={signed}
      />
      <TableRow
        titleId='operation'
        value={operation}
      />
      <TableRow
        titleId='archived'
        value={archived}
      />
      <TableRow
        titleId='statusColumnHeader'
        customValue={status}
      />
    </TableCard>
  )
}