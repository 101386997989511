import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { contractsApi, tariffsApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import { ColorField } from "src/components/common/ColorField";
import { PageHeader } from "src/components/common/PageHeader";
import { FeedbackForm } from "../../components/forms/FeedbackForm";
import AMPModal from "../../components/common/AMPModal";
import { ContractDetails } from "src/components/contracts/ContractDetails";
import { TariffCard } from "src/components/tariffs/TariffCard";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { Signature } from "src/components/common/Signature";
import { ContractStatusEnum, RoleEnum } from "src/enums";
import { PdfDocument } from "src/components/common/PdfDocument";
import { Can } from "src/permissions/Can";
import { archiveButtonSx, headerSx, openModalBtn } from "./ContractInfo.sx";
import { MoveOperationButton } from "src/components/contracts/MoveOperationButton";
import { useGetUserLanguage } from "../../hooks/useGetUserLanguage";
import { isErrorObject } from "src/utils/helper";
import { NotifyError, NotifySuccess } from "src/utils/notification";
import { AuthUserDTO } from "../../types";
import { FeedbackTypeEnum } from "../../types";
import ability from "src/permissions/ability";

type GetContractByIdQueryTrigger = ReturnType<typeof contractsApi.useLazyGetContractByIdQuery>[0];
type SignContractMutationTrigger = ReturnType<typeof contractsApi.useSignContractMutation>[0]

const handleGetContractInfo = (trigger: GetContractByIdQueryTrigger) => (async (businessId: number, contractId: number, userId?: number) => {
  const params = {
    businessId,
    contractId,
    userId
  }
  if(!userId){
    delete params.userId
  }
  store.dispatch(preloaderChangeStatus(true))
  try {
    return await trigger(params).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

const handleSubmitSignature = (trigger: SignContractMutationTrigger, dispatch: AppDispatch, authInfo: AuthUserDTO, contractId: number) => (async (file: File) => {
  dispatch(preloaderChangeStatus(true))
  try{
    const formData = new FormData()
    formData.append('file', file)
    formData.set('userId', String(authInfo.id))
    await trigger({contractId, formData}).unwrap()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
})

export const ContractInfo: FC = () => {
  const { id } = useParams()
  const intl = useIntl();
  const { authInfo, activeBusiness } = useAppSelector((state) => state.common)
  const [trigger, response] = contractsApi.useLazyGetContractByIdQuery();
  const [archiveContract] = contractsApi.useArchiveContractMutation();

  const [getTariff, tariff] = tariffsApi.useLazyGetTariffByIdQuery();
  const [getContarctPdf, contractPdf] = contractsApi.useLazyContractPdfQuery();
  const [pdfAsImage, setPdfAsImage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [currentLanguage] = useGetUserLanguage()
  const [triggerSignature] = contractsApi.useSignContractMutation()
  const dispatch = useAppDispatch()
  
  const canChangeStatus = response.data?.status === ContractStatusEnum.INSTALLATION && ability.can('manage', 'contracts')
  const canArchive = ability.can('read', 'archiveContract')

  useEffect(() => {
    const userId = authInfo?.roles?.[0]?.role === RoleEnum.DRIVER ? +authInfo.id : 0
    id && handleGetContractInfo(trigger)(activeBusiness, +id, userId)
  }, [id])
  
  const getData = async () => {
    if(response.data) {
      await getTariff({businessId: response.data.business.id, tariffId: response.data.tariff.id}).unwrap()
      response.data.status !== ContractStatusEnum.WAIT_FOR_SIGNATURE && await getContarctPdf({businessId: response.data.business.id, contractId: response.data.id, userId: response.data.user.id}).unwrap()
    }
  }
  
  useEffect(() => {
    getData()
  }, [response])
 
  useEffect(() => {
    if(contractPdf.data){
      setPdfAsImage(contractPdf.data)    
    }
  }, [contractPdf])

  const handleChangePlanModal = (status: boolean) => {
    return () => setOpen(status);
  };

  const handleArchive = async () => {
    if (!id) {
      return;
    }

    try {
      const formData = new FormData()
      formData.set('businessId', String(response.data?.business?.id ?? ''))
      await archiveContract({ contractId: Number(id), formData })
      NotifySuccess('Contract archived')
    } catch (error) {
      if(isErrorObject(error)){
        NotifyError(error.data.message)
      }
    } 
  }

  return (
    <>
      {response.data && <Box>
        <Box sx={headerSx}>
          <PageHeader
            title={response.data.name}
            onlyTitle
          >
            <ColorField label={response.data?.status} />
          </PageHeader>
          {canChangeStatus && <MoveOperationButton
            contractId={response.data.id}
            businessId={response.data.business.id}
          />}
        </Box>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={4}
          >
            {tariff.data && (
              <>
                <TariffCard
                  language={currentLanguage}
                  data={tariff.data}
                  selected={true}
                />
                <Can
                  I="read"
                  a="changePlan"
                >
                  <Button
                    onClick={handleChangePlanModal(true)}
                    variant="contained"
                    color="brand"
                    sx={openModalBtn}
                  >
                    {intl.formatMessage({id: "changeMyPlan"})}
                  </Button>
                </Can>
              </>
            )}
            {
              response?.data?.status !== ContractStatusEnum.ARCHIVED && canArchive && 
               <Button
                 variant="contained"
                 onClick={handleArchive}
                 color="inherit"
                 sx={archiveButtonSx}
               >
                 <Typography
                   variant="button"
                   color="textSecondary"
                 >
                   {intl.formatMessage({ id: 'archiveContract' })}
                 </Typography>
               </Button>
            }
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <ContractDetails
                data={response.data}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Can
                I="read"
                a="contract"
              >

                {response.data.status === ContractStatusEnum.WAIT_FOR_SIGNATURE &&
                  <Can
                    I="signing"
                    a="contract"
                  >
                    <Signature
                      handleSubmit={
                        handleSubmitSignature(
                          triggerSignature,
                          dispatch,
                          authInfo,
                          Number(id)
                        )}
                      signature={response.data.userSignatureUrl ?? ''}
                    />
                  </Can>
                }
                {response.data.status !== ContractStatusEnum.WAIT_FOR_SIGNATURE && <PdfDocument 
                  pdfLink={pdfAsImage}
                  userName={`${response.data.user.firstName}${response.data.user.lastName}`}
                  date={response.data.user.created}
                  businessId={response?.data?.business?.id}
                  contractId={response?.data?.id}
                />}
              </Can>
            </Grid>
          </Grid>
        </Grid>
      </Box>}
      <AMPModal
        handleClose={handleChangePlanModal(false)}
        open={open}
        title={intl.formatMessage({ id: 'changeTariff' })}
        type="lg"
        fullWidth
      >
        <FeedbackForm
          type={FeedbackTypeEnum.CHANGE_TARIFF}
          handleClose={handleChangePlanModal(false)}
        />
      </AMPModal>
      {/* TODO: add a contract for this page */}
    </>
  )
} 
