import { SxProps, Theme } from '@mui/material';
import theme from 'src/theme';

export const rootSx: SxProps<Theme> = {
  width: '100%',
  padding: theme.spacing(24/8),
  background: theme.palette.text.secondary,
  borderRadius: `${theme.spacing(8/8)} ${theme.spacing(8/8)} 0 0`,
  overflow: 'hidden'
}
export const tableFooterSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(10/8, 0),
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  }
}
export const titleSx: SxProps<Theme> = {
  marginBottom: theme.spacing(10/8),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}

export const filtersSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'flex-start'
}
