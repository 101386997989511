export enum ContractSortFieldEnum {
  CLIENT_EMAIL = "CLIENT_EMAIL",
  CONTRACT_NAME = "CONTRACT_NAME",
  CLIENT_LAST_NAME = "CLIENT_LAST_NAME",
  CREATED = "CREATED",
  LOCATION_NAME = "LOCATION_NAME",
  STATUS = "STATUS"
}

export enum ContractStatusEnum {
  INSTALLATION = "INSTALLATION",
  OPERATION = "OPERATION",
  REJECTED = "REJECTED",
  VALIDATION = "VALIDATION",
  WAIT_FOR_SIGNATURE = "WAIT_FOR_SIGNATURE",
  ARCHIVED = "ARCHIVED"
}

export enum ContractStatusManualEnum {
  INSTALLATION = "INSTALLATION",
  OPERATION = "OPERATION"
}

