import { GridColumns } from "@mui/x-data-grid";
import { Theme, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { colTitle, statusSx } from "./ContractsHistory.sx";
import { AuthContractDTO } from "src/types";
import { Link } from "react-router-dom";

export const columns = (theme: Theme): GridColumns => ([
  {
    field: "name",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"name"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const name = data?.name;
      const id = data?.id;

      return (
        <Link
          to={`/app/contracts/${id}`}
        >
          <Typography color="primary.dark">{name}</Typography>
        </Link>)
    } 
  },
  {
    field: "created",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"createdOnColumnHeader"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const created = data?.created;
      return <Typography>
        {(new Date(created)).toLocaleDateString('fr-CH')}
      </Typography>
    } 
  },
  {
    field: "signed",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"signedOn"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const signedAt = data?.signedAt;
      return <Typography>
        {signedAt ? (new Date(signedAt)).toLocaleDateString('fr-CH') : '-'}
      </Typography>
    } 
  },
  {
    field: "operation",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"operation"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const operationalAt = data?.operationalAt;
      return <Typography>
        {operationalAt ? (new Date(operationalAt)).toLocaleDateString('fr-CH') : '-'}
      </Typography>
    } 
  },
  {
    field: "archived",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"archived"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const archivedAt = data?.archivedAt;
      return <Typography>
        {archivedAt ? (new Date(archivedAt)).toLocaleDateString('fr-CH') : '-'}
      </Typography>
    } 
  },
  {
    field: "status",
    renderHeader: () =>
      <Typography sx={colTitle}>
        <FormattedMessage id={"statusColumnHeader"} />
      </Typography>,
    minWidth: 50,
    flex: 1,
    sortable: true,
    renderCell: (params) => {
      const data = params?.row as AuthContractDTO;
      const status = data?.status;
      return <Typography sx={statusSx(theme, status)}>
        {status}
      </Typography>
    } 
  },
])