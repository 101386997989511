import { Box, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { chargersApi, usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { ActiveCharging } from "src/components/chargers/ActiveCharging";
import { ChargerDetails } from "src/components/chargers/ChargerDetails";
import { ChargerSchedule } from "src/components/chargers/ChargerSchedule";
import { ChargerTransactionsTable } from "src/components/chargers/ChargerTransactionsTable";
import { PageHeader } from "src/components/common/PageHeader";
import { PowerConsumption } from "src/components/common/PowerConsumption";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, ChargePointByIdRequest } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { chargerInfoSx } from "./ChargerInfo.sx";
import { ContractStatusEnum } from "src/enums";

type ChargersQueryTrigger = ReturnType<typeof chargersApi.useLazyGetChargePointByIdQuery>[0];

const handleGetChargerInfo = (trigger: ChargersQueryTrigger, dispatch: AppDispatch) => (async (data: ChargePointByIdRequest) => {
  store.dispatch(preloaderChangeStatus(true))

  try {
    const response = await trigger(data).unwrap()
    store.dispatch(preloaderChangeStatus(false))
    return response
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const ChargerInfo: FC = () => {
  const params = useParams();
  const { activeBusiness } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch()
  const [trigger, response] = chargersApi.useLazyGetChargePointByIdQuery()

  const [getUserInfo] = usersApi.useLazyGetUserByIdQuery()

  const handleGetUserData = async () => {
    response.data?.user && response.data?.business && await getUserInfo({ userId: response.data?.user.id, businessId: response.data?.business.id })
  }

  useEffect(() => {
    handleGetUserData()
  }, [response])

  useEffect(() => {
    params.id && handleGetChargerInfo(trigger, dispatch)({ chargerId: +params.id, businessId: activeBusiness })
  }, [])

  const contract = response?.data?.user?.contracts?.find(contract => contract?.status === ContractStatusEnum.OPERATION)

  return (
    <Box>
      <PageHeader
        title={`${response.data?.chargePointModel} ${response.data?.chargePointId}` ?? ''}
        onlyTitle
      />
      {response.data &&
        <Grid
          container
          spacing={3}
        >
          <Grid
            container
            spacing={3}
            item
            xs={12}
            lg={response.data.connectors?.length ? 9 : 12}
          >
            <Grid
              item
              xs={12}
            >
              <ChargerDetails
                data={response.data}
              />
            </Grid>
            {Boolean(response.data.connectors?.length) &&
              <Grid
                item
                xs={12}
                sx={chargerInfoSx.activeChargingResponsiveSmSx}
              >
                {response.data.defaultOcppTag && <ActiveCharging
                  data={response.data}
                />}
                <ChargerSchedule
                  data={response.data}
                />
              </Grid>
            }
            <Grid
              item
              xs={12}
            >
              {response.data.user && <PowerConsumption
                userId={response.data.user.id}
                dateFrom={contract?.created ?? 0}
                businessId={response.data.business.id}
                chargerId={response.data.id}
                locationId={response.data.location.id}
                annualWh={contract?.tariff?.annualWhLimit ?? 0}
              />}
            </Grid>
            {response.data && <Grid
              item
              xs={12}
            >
              <ChargerTransactionsTable
                chargerId={response.data.id}
                businessId={response.data.business.id}
              />
            </Grid>}
          </Grid>
          {Boolean(response.data.connectors?.length) &&
            <Grid
              container
              spacing={3}
              item
              xs={12}
              lg={3}
              sx={chargerInfoSx.activeChargingResponsiveLgSx}
            >
              <Grid
                item
                xs={12}
              >
                {response.data.defaultOcppTag && <ActiveCharging
                  data={response.data}
                />}
                <ChargerSchedule
                  data={response.data}
                />
              </Grid>
            </Grid>}
        </Grid>}
    </Box>
  );
}
