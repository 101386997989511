import { FC } from "react";
import dayjs from "dayjs";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { usersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { getHelperText } from "src/utils/forms";
import { editUserFormSchema, EditUserFormType } from "../formData/editUser";
import { UserUpdateDTO } from "../../../types";
import { titles } from "../formData/common/initialData";
import { AMPFormSelect } from "../../common/AMPFormSelect";
import { LanguageEnum, TitleEnum } from "../../../enums";
import { EditUserFormTypes } from "./EditUserForm.types";
import { actionsWrapperSx, deleteBtnSx, formContentSx, formSx } from "./EditUserForm.sx";
import { isErrorObject, processedLanguages, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { AMPPhoneNumber } from "../../common/AMPPhoneNumber";
import { TariffType } from "../formData/tariff";

type EditUserMutationTrigger = ReturnType<typeof usersApi.useUpdateUserMutation>[0];

const handleSubmit =
  (
    trigger: EditUserMutationTrigger,
    dispatch: AppDispatch,
    userId: number | undefined,
    handleClose: () => void
  ): FormikConfig<EditUserFormType>["onSubmit"] => {
    return async (values) => {
      const value = valuesTrimmer(values)
      try {
        dispatch(preloaderChangeStatus(true));
        const {
          phoneNumber,
          title,
          language
        } = value;
        const data: UserUpdateDTO = {
          phoneNumber,
          title,
          language
        };
        if (userId) {
          await trigger({data, userId}).unwrap()
        }
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };
  }

export const EditUserForm: FC<EditUserFormTypes> = ({handleClose, userData, handleShowDeleteModal }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [trigger] = usersApi.useUpdateUserMutation();
  const [triggerDeleteAccount] = usersApi.useDeleteUserAccountMutation();
  const navigate = useNavigate();

  const EditUserInitialValue = {
    phoneNumber: userData?.phoneNumber ?? "",
    title: userData?.title ?? TitleEnum.MR,
    language: userData?.language ?? LanguageEnum.ENGLISH
  }

  const handleDeleteAccount = async () => {
    const contract = userData?.contracts?.find(contract => contract.active)
    const contractDate = contract?.created
      ? dayjs(contract?.created)
      : dayjs();
    const today = dayjs();
    const diff = today.diff(contractDate, "days");
    if ((diff > 730 || !contract) && userData?.id && userData?.business?.id) {
      try {
        dispatch(preloaderChangeStatus(true));
        await triggerDeleteAccount({
          userId: userData.id,
          data: {businessId: userData.business.id}
        }).unwrap()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        navigate( "/app/users");
        dispatch(preloaderChangeStatus(false));
      }
    } else {
      handleShowDeleteModal(true)();
      handleClose();
    }
  }
  
  const handleChangePhone = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string) =>
    (newValue: string | number | null | undefined) => {
      if(newValue){
        setFieldValue(fieldName, `+${newValue}`)
      }
    }

  return (
    <Formik
      initialValues={EditUserInitialValue}
      validationSchema={editUserFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, userData?.id,  handleClose)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }: FormikProps<EditUserFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box sx={formContentSx}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={6}
                  item
                >
                  <AMPFormSelect
                    data={titles(intl)}
                    value={values["title"] as string}
                    label={"title"}
                    input={"title"}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  xs={6}
                  item
                >
                  <AMPFormSelect
                    data={processedLanguages(intl)}
                    value={values["language"] as string}
                    label={"language"}
                    input={"language"}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  xs={6}
                  item
                >
                  <AMPPhoneNumber
                    value={values["phoneNumber"]}
                    onChange={handleChangePhone(setFieldValue, "phoneNumber")}
                    label={"phoneNumber"}
                    onBlur={handleBlur}
                    hasError={
                      touched["phoneNumber"] && Boolean(errors["phoneNumber"])
                    }
                    helperText={getHelperText("phoneNumber", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
              >
                <Grid
                  xs={3}
                  item
                >
                  <Button
                    onClick={handleDeleteAccount}
                    variant="outlined"
                    color="error"
                    sx={deleteBtnSx}
                  >
                    {intl.formatMessage({id: "deleteAccount"})}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <SubmitButton
                text="save"
                color="primary"
                isSubmitting={isSubmitting}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
