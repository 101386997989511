import { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, useTheme } from "@mui/material";
import { ResponsiveTable } from "../ResponsiveTable";
import { Table } from "../../tables/Table";
import { ContractHistoryCard } from "./ContractHistoryCard";
import { columns } from "./ContractsHistory.columns";
import { ContractsHistoryProps } from "./ContractsHistory.types";
import { statusSx, tablesx, titleSx, wrapperSx } from "./ContractsHistory.sx";

export const ContractsHistory:FC<ContractsHistoryProps> = ({ contracts }) => {
  const intl = useIntl();
  const theme = useTheme()

  return (
    <Box sx={wrapperSx(theme)}>
      <Typography sx={titleSx(theme)}>
        {intl.formatMessage({ id: "contractsHistory" })}
      </Typography>

      <ResponsiveTable
        desktopElement={
          <Table
            sx={tablesx(theme)}
            rows={contracts ?? []}
            hideFooter
            autoHeight
            columns={columns(theme)}
          />
        }
        responsiveElement={
          (contracts ?? []).map(contract => <ContractHistoryCard
            key={contract?.id}
            name={contract.name}
            link={`/app/contracts/${contract.id}`}
            created={(new Date(contract.created)).toLocaleDateString('fr-CH')}
            signed={contract?.signedAt ?(new Date(contract.signedAt)).toLocaleDateString('fr-CH'): '-'}
            archived={contract.archivedAt ? (new Date(contract.archivedAt)).toLocaleDateString('fr-CH') : '-'}
            status={
              <Typography sx={statusSx(theme, contract?.status)}>
                {contract?.status}
              </Typography>}
            operation={contract?.operationalAt ?(new Date(contract.operationalAt)).toLocaleDateString('fr-CH') : '-' }        
          />) 
        }
      />
    </Box>
  )
}