import { FC } from "react";
import { FormikConfig } from "formik";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { ChargerForm } from "src/components/forms/ChargerForm";
import { ChargerType } from "src/components/forms/formData/charger";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, AuthUserDTO, ChargePointCreateRequest } from "src/types";
import { NotifyError } from "src/utils/notification";
import { ChargerCreateFormWrapperType } from "./ChargerCreateFormWrapper.types";
import { IntlShape, useIntl } from "react-intl";
import { valuesTrimmer } from "../../../utils/helper";

type ChargerCreateMutationTrigger = ReturnType<typeof chargersApi.useAddChargerMutation>[0]

const handleSubmit = (trigger: ChargerCreateMutationTrigger, dispatch: AppDispatch, authInfo: AuthUserDTO, handleClose: () => void, intl: IntlShape): FormikConfig<ChargerType>['onSubmit'] => (async (value, helpers) => {
  const values = valuesTrimmer(value)

  dispatch(preloaderChangeStatus(true))
  const data: ChargePointCreateRequest = {
    businessId: authInfo.business.id,
    chargePointId: values.chargePointId, 
    modelId: +values.modelId,
  }

  if(values.userId) {
    data.userId = +values.userId
  }
  if(values.locationId){
    data.locationId = +values.locationId
  }
  
  try {
    await trigger(data).unwrap()
    handleClose()
  } catch (error: any) {
    error.status === 409
      ? NotifyError(intl.formatMessage({id: "chargePointErrorUnique"}))
      : NotifyError(`ERROR ${error.data.error}`);
  } finally {
    helpers.setSubmitting(false)
    dispatch(preloaderChangeStatus(false));
  }
});

export const ChargerCreateFormWrapper: FC<ChargerCreateFormWrapperType> = ({ handleClose, authInfo }) => {
  const intl = useIntl()
  const [trigger, response] = chargersApi.useAddChargerMutation()
  const dispatch = useAppDispatch()
  
  return (
    <ChargerForm
      handleClose={handleClose}
      handleSubmit={handleSubmit(trigger, dispatch, authInfo, handleClose, intl)}
      authInfo={authInfo}
    />
  )
}
