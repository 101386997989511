import { FC, SyntheticEvent, useEffect, useMemo, useState, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import { FilterList } from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { AMPAutocomplete } from "../../../../components/common/AMPAutocomplete";
import { usersApi } from "../../../../api";
import { DateSelect } from "../../../../components/common/DateSelect";
import { CustomFormLabel } from "src/components/common/CustomFormLabel";
import { AutocompleteValue, UserDTO } from "../../../../types";
import { findAutocompleteValueById, handleScroll } from "../../../../utils/helper";
import { DriverSearchProps } from "./DriverSearch.types";
import { DatesType } from "src/pages/Reports/Reports.types";
import { filterButtonSx, filterIconActiveSx, filterTextSx, filterWrapperSx, formWrapperSx, popupFooterSx } from "./DriverSearch.sx";
import { Can } from "src/permissions/Can";

const pageSize = 10;
const datesInit = {
  allSessions: {
    from: undefined,
    to: dayjs().valueOf()
  }
}

export const DriverSearch:FC<DriverSearchProps> = ({
  handleSetDriverId,
  businessId,
  handleChangeDates,
}) => {
  const intl = useIntl();
  const [getDrivers, drivers] = usersApi.useLazyGetUsersQuery();

  const [driversData, setDriversData] = useState<Array<UserDTO>>([]);
  const [currentDriver, setCurrentDriver] = useState<number | undefined>(undefined);
  const [pageDrivers, setPageDrivers] = useState(0);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
  const [dates, setDates] = useState<DatesType>(datesInit)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [curTime, setCurTime] = useState(4)

  const open = Boolean(anchorEl);

  const handleChangeCurTime = (num: number) => {
    setCurTime(num);
  }

  const handleGetDrivers = async (key: string) => {
    await getDrivers({
      data: {
        keyword: key,
        businessId
      },
      params: {
        page: pageDrivers,
        size: pageSize
      }})
  }

  const handleScrollDrivers = () => {
    handleScroll(
      drivers?.data?.number ?? 0,
      drivers?.data?.totalPages ?? 0,
      pageDrivers,
      setPageDrivers
    )
  }
  
  const handleInputDriver = (event: SyntheticEvent, value: string) => {
    handleGetDrivers(
      value
    )
  }

  const processedDrivers = useMemo(() => {
    if(!driversData.length){
      return []
    }
    return driversData.map((driver) => ({
      id: driver.id,
      label: `${driver.firstName} ${driver.lastName}`
    }))
  }, [driversData])

  const handleChangeDriver = (event: SyntheticEvent, value: AutocompleteValue | null) => {
    setCurrentDriver(value?.id);
  }

  const handleApply = () => {
    handleSetDriverId(currentDriver);
    handleChangeDates(dates)
    setAnchorEl(null);
  }

  const handleReset = () => {
    handleSetDriverId(undefined);
    setCurrentDriver(undefined);
    setDates(datesInit)
    handleChangeDates(datesInit)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterActive(true);
  };  

  const handleClose = () => {
    setAnchorEl(null);
    setIsFilterActive(false);
  };

  const handleSetDates = (
    newFrom: number,
    newTo: number,
    field: string
  ) => {
    return () => {
      setDates({
        [field]: {
          from: newFrom,
          to: newTo
        }
      })
    }
  }

  useEffect(() => {
    const content = drivers?.data?.content ?? []
    const allData = [...driversData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setDriversData(Array.from(Object.values(allData)))
  }, [drivers?.data?.content])

  return (
    <Box sx={filterWrapperSx}>
      <IconButton
        sx={filterButtonSx}
        onClick={handleClick}
      >
        {!isFilterActive && <FilterList /> }
        {isFilterActive && <FilterList sx={filterIconActiveSx} />}
        <Typography variant='body1'
          sx={filterTextSx}>
          <FormattedMessage id='filter' />
        </Typography>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={formWrapperSx}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >   
        <CustomFormLabel
          label={intl.formatMessage({ id: 'duration' })}
        />
        <DateSelect
          handleTimeChange={handleSetDates}
          field={Object.keys(dates)[0]}  
          currentTimeInit={curTime}
          handleSaveCurrentTimeIndex={handleChangeCurTime}
        />     
        <Can 
          I="read"
          a="users"
        >
          <AMPAutocomplete
            options={processedDrivers ?? []}
            value={findAutocompleteValueById(processedDrivers, currentDriver ?? undefined)}
            handleScroll={handleScrollDrivers}
            onInputChange={handleInputDriver}
            input={'userId'}
            onChange={handleChangeDriver}
            label={intl.formatMessage({id: 'user'})}
          />
        </Can>
        <Box sx={popupFooterSx}>
          <Button 
            onClick={handleReset}
          >
            {intl.formatMessage({id: 'reset'})}
          </Button>
          <Button
            onClick={handleApply}
          >
            {intl.formatMessage({id: 'apply'})}
          </Button>
        </Box>
      </Popover>
    </Box>
    
  )
}