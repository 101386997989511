import { FormikConfig } from "formik";
import { FC } from "react";
import { chargersApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, DataType, UpdateChargePointRequestData } from "src/types";
import { ChargerForm } from "src/components/forms/ChargerForm";
import { ChargerUpdateFormWrapperType } from "./ChargerUpdateFormWrapper.types";
import { ChargerType } from "src/components/forms/formData/charger";
import { useIntl } from "react-intl";
import { NotifyError } from "src/utils/notification";
import { valuesTrimmer } from "../../../utils/helper";

type ChargerUpdateMutationTrigger = ReturnType<typeof chargersApi.useUpdateChargePointMutation>[0]

const handleSubmit = (trigger: ChargerUpdateMutationTrigger, dispatch: AppDispatch, businessId: number, handleClose: () => void, chargerId: number, errorMessage: string): FormikConfig<ChargerType>['onSubmit'] => (async (value, helpers) => {
  const values = valuesTrimmer(value)
  dispatch(preloaderChangeStatus(true))
  const newData: DataType = {
    chargePointId: values.chargePointId, 
    locationId: +values.locationId,
    modelId: +values.modelId,
    userId: +values.userId
  }

  Object.keys(values).map((item: string) => {
    !newData[item] && delete newData[item]
  })
  
  const data: UpdateChargePointRequestData = {
    ...newData,
    businessId: businessId
  }
  try {
    await trigger({ chargerId, data }).unwrap()
    handleClose()
  } catch (error: any) {
    if(error.status >= 500){
      NotifyError(`${errorMessage} ${error?.status ?? ''} ${error?.data?.message ?? ''}`)
    } else {
      NotifyError(`${error?.status ?? ''} ${error?.data?.message ?? ''}`)
    }
  } finally {
    helpers.setSubmitting(false)
    dispatch(preloaderChangeStatus(false));
  }
});

export const ChargerUpdateFormWrapper: FC<ChargerUpdateFormWrapperType> = ({ handleClose, authInfo, initialValue }) => {
  const intl = useIntl()
  const [trigger] = chargersApi.useUpdateChargePointMutation()
  const dispatch = useAppDispatch()
  const errorMessage = intl.formatMessage({id: 'serverSideError'}) 
  return (
    <ChargerForm
      handleClose={handleClose}
      authInfo={authInfo}
      initialValue={initialValue}
      handleSubmit={handleSubmit(trigger, dispatch, initialValue.business.id, handleClose, initialValue.id, errorMessage)}
    />
  )
}
