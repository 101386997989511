import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { contractsApi, usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import AMPModal from "src/components/common/AMPModal";
import { Signature } from "src/components/common/Signature";
import { preloaderChangeStatus, setAuthInfo } from "src/stores/common/commonSlice";
import { AuthUserDTO } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { contentItemSx } from "./SignatureContract.sx";
import { ContractStatusEnum, RoleEnum } from "src/enums";

type SignContractMutationTrigger = ReturnType<typeof contractsApi.useSignContractMutation>[0]
type GetUserByIdQuery = ReturnType<typeof usersApi.useLazyGetUserByIdQuery>[0]

const handleSubmit = (trigger: SignContractMutationTrigger, dispatch: AppDispatch, authInfo: AuthUserDTO, handleClose: () => void, getUserById: GetUserByIdQuery) => (async (file: File) => {
  dispatch(preloaderChangeStatus(true))
  try{
    const formData = new FormData()
    formData.append('file', file)
    formData.set('userId', String(authInfo.id))
    const contractId = authInfo.contracts.find(contract => contract.status === ContractStatusEnum.WAIT_FOR_SIGNATURE)?.id;
    if (contractId) {
      await trigger({contractId, formData}).unwrap();

    }

    const isDriver = authInfo.roles.
      map((role: {role: RoleEnum}) => role.role).
      includes(RoleEnum.DRIVER);

    if (isDriver) {
      try {
          
        const response = await getUserById({
          userId: authInfo.id,
          businessId: authInfo.business.id
        }).unwrap();
    
        console.log('AFTER REQUESTING CHARGER', response)
    
        dispatch(setAuthInfo(response))
      } catch(e) {
        console.error('COULD NOT GET CURRENT DRIVER', e);
      }
    }
    handleClose()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }

})

export const SignatureContract: FC = () => {
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({id: 'putSignature'})
  
  const { authInfo } = useAppSelector(state => state.common)
  const dispatch = useAppDispatch()

  const [trigger] = contractsApi.useSignContractMutation()
  const [getDriver] = usersApi.useLazyGetUserByIdQuery();

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Grid container
        spacing={5}>
        <Grid item
          xs={12}
          sx={contentItemSx}>
          <img src="/static/images/create-request.png" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={contentItemSx}
        >
          <Typography
            variant="body1"
            color="neutral.500"
            fontSize="1rem"
          >
            <FormattedMessage id="welcomeContent" />
          </Typography>
        </Grid>
        <Grid item
          xs={12}
          sx={contentItemSx}>
          <Button color="primary"
            variant="contained"
            onClick={handleOpen}>
            <FormattedMessage id="signAContract" />
          </Button>
        </Grid>
      </Grid>
      <AMPModal
        handleClose={handleClose}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Signature
          handleSubmit={handleSubmit(trigger, dispatch, authInfo, handleClose, getDriver)}
          modal
          handleClose={handleClose}
        />
      </AMPModal>
    </>
  );
}
