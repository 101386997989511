import { FC } from "react";

export const VerifyEmailIcon: FC = () => (
  <svg
    width="117"
    height="96"
    viewBox="0 0 117 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.3832 93.7208C83.1488 93.7208 104.063 72.9608 104.063 47.3604C104.063 21.76 83.1488 1 57.3832 1C31.6175 1 10.7031 21.76 10.7031 47.3604C10.7031 72.9608 31.6175 93.7208 57.3832 93.7208Z"
      fill="#F5F5F7"
      stroke="#DFDFE8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M5.0961 41.7671C6.821 41.7671 8.22248 40.4763 8.22248 38.6477C8.22248 36.8191 6.821 35.5283 5.0961 35.5283C3.3712 35.5283 1.96973 36.8191 1.96973 38.6477C1.96973 40.4763 3.47901 41.7671 5.0961
      41.7671Z"
      fill="#F1F3FA"
    />
    <path
      d="M2.0784 31.8709C3.26427 31.8709 4.12672 30.9029 4.12672 29.7197C4.12672 28.644 3.15646 27.5684 2.0784 27.5684C1.00034 27.5684 0.0300841 28.5364 0.0300841 29.7197C0.185528 31.0104 0.784727 31.8709 2.0784
      31.8709Z"
      fill="#F1F3FA"
    />
    <path
      d="M113.011 49.5115C115.167 49.5115 117 47.7905 117 45.5317C117 43.2728 115.275 41.5518 113.011 41.5518C110.855 41.5518 109.022 43.2728 109.022 45.5317C109.13 47.7905 110.855 49.5115 113.011
      49.5115Z"
      fill="#F1F3FA"
    />
    <path
      d="M99.8589 80.3826H15.5545C12.1047 80.3826 9.30176 77.5859 9.30176 74.1438V22.8354C9.30176 19.3934 12.1047 16.5967 15.5545 16.5967H99.7511C103.201 16.5967 106.004 19.3934 106.004 22.8354V74.1438C106.112 77.5859 103.309 80.3826 99.8589 80.3826Z"
      fill="white"
      stroke="#DFDFE8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5537 33.269V24.879C15.5537 23.5882 16.524 22.6201 17.8176 22.6201H97.7019C98.9956 22.6201 99.9659 23.5882 99.9659 24.879V71.9923C99.9659 73.2831 98.9956 74.2512 97.7019 74.2512H17.8176C16.524 74.2512 15.5537 73.2831 15.5537 71 9923V51.555"
      fill="white"
    />
    <path
      d="M15.5537 33.269V24.879C15.5537 23.5882 16.524 22.6201 17.8176 22.6201H97.7019C98.9956 22.6201 99.9659 23.5882 99.9659 24.879V71.9923C99.9659 73.2831 98.9956 74.2512 97.7019 74.2512H17.8176C16.524 74.2512 15.5537 73.2831 15.5537 71.9923V51.555"
      stroke="#DFDFE8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5537 46.7148V40.1533"
      stroke="#D6DDE8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M73.8465 81.8285C73.5102 83.2608 73.0057 84.7773 72.4172 86.0411C70.8197 89.1584 68.2973 91.6017 65.1864 93.2024C61.9914 94.8032 58.2079 95.4772 54.4244 94.6347C45.512 92.7812 39.7947 84.019 41.6444 75.0884C43.4942 66.1577 52.1542 60.3443 61.0666 62.2821C64.2616 62.9561 67.0361 64.5569 69.3903 66.7474C73.342 70.7073 75.0236 76.4364 73.8465 81.8285Z"
      fill="#DFDFE8"
      stroke="#B3B3C9"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M48.9756 79.333L49.669 78.6411L48.9756 79.333L54.4993 84.8681C55.421 85.7917 56.9162 85.7917 57.8379 84.8681L65.911 76.7784C66.8315 75.8559 66.8315 74.3612 65.911 73.4388C64.9893 72.5152 63.4941 72.5152 62.5724 73.4388L56.1686 79.8558L52.3141 75.9934C51.3924 75.0699 49.8972 75.0699 48.9756 75.9934C48.055 76.9159 48.055 78.4106 48.9756 79.333Z"
      fill="white"
      stroke="#B3B3C9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.5856 67.0194L62.542 51.6914"
      stroke="#DFDFE8"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M89.8478 28.245C90.569 27.8373 91.4841 28.0915 91.8918 28.8126C92.2994 29.5338 92.0453 30.4489 91.3241 30.8566L89.8478 28.245ZM23.563 30.8566C22.8418 30.4489 22.5877 29.5338 22.9953 28.8126C23.403 28.0915 24.3181 27.8373 25.0393 28.245L23.563 30.8566ZM52.5227 45.5034L53.2608 44.1976L52.5227 45.5034ZM91.3241 30.8566L63.1026 46.8092L61.6263 44.1976L89.8478 28.245L91.3241 30.8566ZM25.0393 28.245L53.2608 44.1976L51.7845 46.8092L23.563 30.8566L25.0393 28.245ZM63.1026 46.8092C59.591 48.7942 55.2961 48.7942 51.7845 46.8092L53.2608 44.1976C55.8563 45.6647 59.0308 45.6647 61.6263 44.1976L63.1026
      46.8092Z"
      fill="#DFDFE8"
    />
    <path
      d="M24.3012 67.0194L52.3447 51.6914"
      stroke="#DFDFE8"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>

)
