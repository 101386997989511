import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AmpWSMessageEnum, AmpWSMessageMap, isWSMessageEnum, LanguageShortEnum } from 'src/enums';
import { AMPERAGE_TYPE, CHARGE_POINT_STATUS_TYPE, CHARGING_STATUS_TYPE, CONFIGURATIONS_TYPE, CONNECTOR_STATUS_TYPE, ContractDTO, UserAvatar, UserDTO } from 'src/types';
import { AuthUserDTO } from 'src/types/auth';
import { destinctSliceCollection } from 'src/utils/helper';
import { RootState } from '../../app/store';

export interface CommonState {
  lang: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  authInfo: AuthUserDTO | {},
  isLoaded: boolean,
  activeBusiness: number,
  CHARGE_POINT_STATUS: CHARGE_POINT_STATUS_TYPE[],
  CHARGING_STATUS: CHARGING_STATUS_TYPE[],
  CONNECTOR_STATUS: CONNECTOR_STATUS_TYPE[],
  CONFIGURATIONS: CONFIGURATIONS_TYPE[],
}

const initialState: CommonState = {
  lang: LanguageShortEnum.EN,
  authInfo: {},
  isLoaded: false,
  activeBusiness: 0,
  CHARGE_POINT_STATUS: [],
  CHARGING_STATUS: [],
  CONFIGURATIONS: [],
  CONNECTOR_STATUS: []
};

export const commonSlice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    changeLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setAuthInfo: (state, action: PayloadAction<AuthUserDTO | UserDTO>) => {
      state.authInfo = action.payload
    },
    clearAuthInfo: (state) => {
      state.authInfo = {}
    },
    preloaderChangeStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload
    },
    setActiveBusiness: (state, action: PayloadAction<number>) => {
      state.activeBusiness = action.payload
    },
    webSocketMessage: (state, action: PayloadAction<string>) => {
      const { type, payload } = JSON.parse(action.payload)
      if (isWSMessageEnum(type)) {
        state[type] = destinctSliceCollection(state[type], payload)
      }
    },
    updateContractStatus: (state, action: PayloadAction<ContractDTO>) => {
      state.authInfo = {
        ...state.authInfo,
        contract: {
          created: action.payload.created,
          id:	action.payload.id,
          name:	action.payload.name,
          status: action.payload.status
        }
      }
    },
    updateUserPhoto: (state, action: PayloadAction<UserAvatar>) => {
      state.authInfo = {
        ...state.authInfo,
        avatar: action.payload
      }
    },
    clearStore: (state) => {
      state.activeBusiness = 0
      state.CHARGE_POINT_STATUS = []
      state.CHARGING_STATUS = []
      state.CONNECTOR_STATUS = []
      state.CONFIGURATIONS = []
    }
  },
});

export const {
  changeLang,
  setAuthInfo,
  clearAuthInfo,
  preloaderChangeStatus,
  updateContractStatus,
  setActiveBusiness,
  webSocketMessage,
  updateUserPhoto,
  clearStore
} = commonSlice.actions;

export const selectLang = (state: RootState) => state.lang.value;

export const getAuthInfo = (state: RootState) => state.authInfo.value;

export const getActiveBusiness = (state: RootState) => state.authInfo.activeBusiness

export default commonSlice.reducer;
