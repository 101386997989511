import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "src/axiosBaseQuery"
import { setAuthInfo } from "src/stores/common/commonSlice"
import { BusinessRegistrationRequest, CompleteRequest, DefaultAuthResponse, DefaultResponse } from "src/types"
import { DriverRegistrationRequest } from "src/types/registration"
import { ManualDriverRegistrationRequest } from "src/types/users"
import { getBaseURL } from "src/utils/helper"

export const registerApi = createApi({
  reducerPath: 'registerApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/registration'),
  }),
  endpoints:(build) => {
    return {
      registerDrivers: build.mutation<DefaultResponse, DriverRegistrationRequest>({
        query: (data) => ({ url: '/drivers', method: 'post', data }),
      }),
      addNewUser: build.mutation<DefaultResponse, ManualDriverRegistrationRequest>({
        query: (data) => ({url: '/drivers/manual', method: 'post', data})
      }),
      confirm: build.mutation<DefaultAuthResponse, string>({
        query: (hash) => ({url: '/confirm', method: 'post', params: { hash }})
      }),
      confirmResend: build.mutation<DefaultAuthResponse, string>({
        query: (email) => ({url: '/confirm/resend', method: 'post', params: { email }})
      }),
      addNewBusiness: build.mutation<DefaultResponse, BusinessRegistrationRequest>({
        query: (data) => ({url: '/business', method: 'post', data})
      }),
      complete: build.mutation<DefaultAuthResponse, CompleteRequest>({
        query: ({data, params}) => ({ url: '/complete', method: 'post', data, params })
      })
    }
  },
})
